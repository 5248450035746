.invite {
  width: 100%;
  background: #6d23ca;
  .desc {
    height: 32px;
    width: 300px;
    box-sizing: border-box;
    background-size: cover;
    justify-content: center;
    align-items: flex-end;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    span.price {
      font-size: 24px;
      color: #fff854;
      margin-left: 3px;
      margin-bottom: 3px;
    }
    span.unit {
      color: #fff854;
      margin: 0 2px;
      margin-bottom: 4px;
    }
  }
  .red-pack {
    position: relative;
    &_bg {
      line-height: 0;
    }
    &_body {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
    &_notice {
      color: #fff;
      font-size: 14px;
      .price {
        color: #fff854;
        margin: 0 6px;
        font-size: 18px;
      }
    }
    &_btn {
      border-radius: 40px;
      background: linear-gradient(180deg, #fff386 0%, #ffdb5a 100%);
      width: 280px;
      height: 42px;
      line-height: 42px;
      color: #990049;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .users {
    box-sizing: border-box;
    border-radius: 10px;
    background: #fcefff;
    padding: 5px;
    & > div {
      border-radius: 6px;
      border: 1px solid rgba(118, 36, 223, 0.3);
      border-radius: 10px;
    }
    &_data {
      border-radius: 5px;
      background: rgba(109, 57, 255, 0.1);
      width: 285px;
      height: 70px;
      margin: 0 auto;
      margin-top: 15px;
      .text {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
      .price {
        color: #ff525b;
        font-size: 24px;
        font-weight: 600;
        &::after {
          content: '元';
          font-size: 12px;
          margin-left: 2px;
        }
      }
    }
    &_list {
      & > div {
        height: 73px;
        border-bottom: 1px solid rgba(118, 36, 223, 0.2);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .box-title {
    .dot {
      width: 4px;
      height: 4px;
      transform: rotate(-45deg);
      background-color: #6816d1;
    }
    .text {
      color: #6816d1;
      font-weight: 600;
      font-size: 16px;
    }
    .line {
      width: 98px;
      height: 1px;
      background: linear-gradient(to right, rgba(109, 57, 255, 0) 0%, rgba(109, 57, 255, 1) 100%);
    }
    .line2 {
      background: linear-gradient(to right, rgba(109, 57, 255, 1) 0%, rgba(109, 57, 255, 0) 100%);
    }
  }
}

.desc-popup {
  width: 275px;
  height: 309px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: linear-gradient(180deg, #fffae8 0%, #fff1c0 100%);
  box-sizing: border-box;
  padding: 5px;
  & > div {
    border: 1px solid rgba(227, 125, 50, 0.5);
    border-radius: 5px;
    height: 100%;
    padding: 0 10px;
    .title {
      color: #443c1e;
      font-size: 16px;
      font-weight: 500;
    }
    .text {
      color: rgba(68, 60, 30, 0.7);
      font-size: 14px;
      line-height: 24px;
    }
    .btn {
      color: #994000;
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      height: 42px;
      border-radius: 40px;
      border: 1px solid rgba(241, 184, 126, 0.5);
      background: linear-gradient(180deg, #fff386 0%, #ffdb5a 100%);
    }
  }
}
