.receive {
  width: 100%;
  background: #6d23ca;
  .body {
    box-sizing: border-box;
    padding: 0 25px;
    padding-bottom: 40px;
    .banner {
      width: 100%;
      display: block;
    }
  }
  .foot {
    position: sticky;
    bottom: 0;
    left: 0;
    width: var(--body-width);
    z-index: 9;
    height: 60px;
    background-color: rgba(140, 72, 225, 1);
    box-sizing: border-box;
    padding: 0 25px;
    &_btn {
      border-radius: 40px;
      background: linear-gradient(180deg, #fff386 0%, #ffdb5a 100%);
      height: 42px;
      width: 100%;
      color: #990049;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .receive-dialog {
    width: 335px;
    height: 335px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100% auto;
    background-repeat: no-repeat;
    text-align: center;
    .btn {
      border-radius: 40px;
      background: linear-gradient(180deg, #fff386 0%, #ffdb5a 100%);
      width: 240px;
      height: 42px;
      color: #990049;
      font-size: 18px;
      font-weight: 600;
      margin: 0 auto;
      margin-top: 16px;
    }
    .close {
      position: absolute;
      bottom: -58px;
      left: 0px;
      width: 100%;
      text-align: center;
      img {
        width: 48px;
        height: 48px;
      }
    }
  }
  .receive-success {
    width: 275px;
    height: 297px;
  }
}
