@import './styles/iconfont.scss';
@import './styles/responsive.scss';
@import './styles/layout.scss';
@import './styles/flex.scss';
@import './styles/font.scss';

* {
  margin: 0;
  padding: 0;
}

body {
  --theme-bg: #f9f9f9;
  --theme-color: #121212;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: var(--theme-color);
}

:root {
  color-scheme: light dark;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.border-box {
  box-sizing: border-box;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-not-allowed:hover {
  cursor: not-allowed;
}

.color-fff {
  color: #fff;
}

@include mobile() {
  body {
    --body-width: 100%;
    background-color: #fff;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .main {
    height: 100%;
    width: 100%;
    background-color: var(--theme-bg);
  }

  .mobile-hidden {
    display: none;
  }
}

@include pc() {
  body {
    --body-width: 414px;
    overflow: hidden;
  }

  #root {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }

  .main {
    height: 736px;
    width: 414px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background-color: var(--theme-bg);
  }

  .pc-hidden {
    display: none;
  }
}
